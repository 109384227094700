import { useMediaQuery } from '@mui/material';

const useGetAppBreakPoints = () => {
  const isMobile = useMediaQuery('(max-width: 480px)');
  const isPortraitTablet = useMediaQuery('(max-width: 768px)');
  const isLandscapeTablet = useMediaQuery('(max-width: 1024px)');
  const isLaptopSM = useMediaQuery('(max-width: 1366px)');

  return {
    isMobile,
    isPortraitTablet,
    isLandscapeTablet,
    isLaptopSM,
  };
};

export default useGetAppBreakPoints;
