import { createSlice } from '@reduxjs/toolkit';
import { authQueryApi } from './query';
import { AuthState } from './types';

const initialState: AuthState = {
  currentUser: null,
};

const authSlice = createSlice({
  name: 'airlineTicketsBooking',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(authQueryApi.endpoints.GetCurrentUser.matchPending, () => {})
      .addMatcher(
        authQueryApi.endpoints.GetCurrentUser.matchFulfilled,
        (state, { payload }) => {
          state.currentUser = payload?.data ?? null;
        }
      )
      .addMatcher(
        authQueryApi.endpoints.GetCurrentUser.matchRejected,
        () => {}
      );
  },
});

export default authSlice.reducer;
