import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InsuranceBookingState,
  TInsuranceSearchResponse,
} from 'features/booking/insurance/types';

const initialState: InsuranceBookingState = {
  searchInsuranceResult: null,
  isInitSearchInsurance: false,
  isSearchingInsurance: false,
};

const insuranceBookingSlice = createSlice({
  name: 'insuranceBooking',
  initialState,
  reducers: {
    setSearchInsuranceResult: (
      state,
      action: PayloadAction<TInsuranceSearchResponse | null>
    ) => {
      state.searchInsuranceResult = action.payload;
    },

    setInitSearchInsuranceState: (state, action: PayloadAction<boolean>) => {
      state.isInitSearchInsurance = action.payload;
    },

    setSearchInsuranceState: (state, action: PayloadAction<boolean>) => {
      state.isSearchingInsurance = action.payload;
    },
  },
});

export const {
  setSearchInsuranceResult,
  setInitSearchInsuranceState,
  setSearchInsuranceState,
} = insuranceBookingSlice.actions;
export default insuranceBookingSlice.reducer;
