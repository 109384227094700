import { baseApi } from 'lib/rtkQuery/baseApi';
import { QueryResponse } from 'lib/rtkQuery/types';
import {
  TUpdatePasswordPayload,
  TUpdateUserAccountPayload,
  TUser,
  TUserAccount,
  TUserAccountsParams,
} from './types';
import { QueryTags } from 'lib/rtkQuery/constants';

const endpoint = '/UserData';

export const userAccountApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [QueryTags.UserAccounts],
  })
  .injectEndpoints({
    endpoints: builder => ({
      GetUserAccounts: builder.query<
        QueryResponse<TUserAccount[]>,
        TUserAccountsParams
      >({
        query: (params: TUserAccountsParams) => ({
          url: `${endpoint}/Search`,
          method: 'GET',
          params,
        }),
        providesTags: [QueryTags.UserAccounts],
      }),

      LookUpUser: builder.query<QueryResponse<TUser[]>, string>({
        query: (keyword: string) => ({
          url: `${endpoint}/Lookup`,
          method: 'GET',
          params: { keyword },
        }),
      }),

      GetUserDetail: builder.query<QueryResponse<TUserAccount>, string>({
        query: (id: string) => ({
          url: `${endpoint}/Get`,
          method: 'GET',
          params: { id },
        }),
      }),

      AddEditUserAccount: builder.mutation<
        QueryResponse<TUserAccount>,
        TUpdateUserAccountPayload
      >({
        query: (payload: TUpdateUserAccountPayload) => ({
          url: `${endpoint}/${payload?.id ? 'Update' : 'Create'}`,
          method: payload?.id ? 'PATCH' : 'POST',
          data: payload,
        }),
        invalidatesTags: [QueryTags.UserAccounts],
      }),

      UpdatePassword: builder.mutation<
        QueryResponse<string>,
        TUpdatePasswordPayload
      >({
        query: (params: TUpdatePasswordPayload) => ({
          url: `${endpoint}/UpdatePassword`,
          method: 'PUT',
          data: params,
        }),
      }),
    }),
  });

export const {
  useGetUserAccountsQuery,
  useLookUpUserQuery,
  useGetUserDetailQuery,
  useAddEditUserAccountMutation,
  useUpdatePasswordMutation,
} = userAccountApi;
