import { ThemeOptions } from '@mui/material/styles/createTheme';
import { hexToRgba } from 'utils/dataTypes/string';

const defaultButtonStyleProps = {
  fontWeight: '500 !important',
  fontSize: 14,
  textTransform: 'none',
  borderRadius: 5,
  padding: '4px 15px',
  lineHeight: '22px',
  border: '1px solid',
} as const;

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#AB9332',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#1E293B',
      contrastText: '#FFF',
    },
    info: {
      main: '#00B8D9',
      contrastText: '#FFF',
    },
    success: {
      main: '#36B37E',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FFAB00',
      contrastText: '#FFF',
    },
    error: {
      main: '#DE4D4D',
      contrastText: '#FFF',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        disableElevation: true,
      },
      styleOverrides: {
        contained: defaultButtonStyleProps,
        outlined: defaultButtonStyleProps,
        text: { ...defaultButtonStyleProps, padding: '5px 15px' },
        sizeMedium: {
          fontWeight: '400',
        },
        sizeSmall: {
          fontWeight: '400',
          fontSize: '1.4rem',
        },
      },
      variants: [
        /** Contained */
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            border: '1px solid transparent',
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            border: '1px solid transparent',
            '&:hover': {
              color: '#FFF',
              background: '#A4A4A6',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            border: '1px solid transparent',
            '&:hover': {
              background: '#3B873E',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            border: '1px solid transparent',
            '&:hover': {
              background: '#0288d1',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            border: '1px solid transparent',
            '&:hover': {
              background: '#B71D18',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            border: '1px solid transparent',
            '&:hover': {
              background: '#B76E00',
            },
          },
        },
        /** Outlined */
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'white',
            border: '1px solid #AB9332',
            '&:hover': {
              background: hexToRgba('#AB9332', 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: 'white',
            border: '1px solid #1E293B !important',
            '&:hover': {
              background: hexToRgba('#1E293B', 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            backgroundColor: 'white',
            border: '1px solid #36B37E',
            '&:hover': {
              background: hexToRgba('#36B37E', 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            border: '1px solid #00B8D9',
            '&:hover': {
              background: hexToRgba('#00B8D9', 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            backgroundColor: 'white',
            border: '1px solid #DE4D4D',
            '&:hover': {
              background: hexToRgba('#DE4D4D', 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            backgroundColor: 'white',
            border: '1px solid #FFAB00',
            '&:hover': {
              background: hexToRgba('#FFAB00', 0.1),
            },
          },
        },
        /** Text */
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            border: '1px solid transparent',
            color: '#AB9332',
            '&:hover': {
              color: '#FFF',
              border: '1px solid #AB9332',
              background: '#AB9332',
            },
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            border: '1px solid transparent',
            color: '#1E293B',
            '&:hover': {
              color: '#FFF',
              background: '#1E293B',
            },
          },
        },
        {
          props: { variant: 'text', color: 'info' },
          style: {
            border: '1px solid transparent',
            color: '#00B8D9',
            '&:hover': {
              color: '#FFF',
              background: '#00B8D9',
            },
          },
        },
        {
          props: { variant: 'text', color: 'success' },
          style: {
            border: '1px solid transparent',
            color: '#36B37E',
            '&:hover': {
              color: '#FFF',
              background: '#36B37E',
            },
          },
        },
        {
          props: { variant: 'text', color: 'warning' },
          style: {
            border: '1px solid transparent',
            color: '#FFAB00',
            '&:hover': {
              color: '#FFF',
              background: '#FFAB00',
            },
          },
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            border: '1px solid transparent',
            color: '#DE4D4D',
            '&:hover': {
              color: '#FFF',
              background: '#DE4D4D',
            },
          },
        },
      ],
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
    mode: 'dark',
  },
  components: {
    ...themeOptions.components,
    MuiButton: {
      ...themeOptions.components?.MuiButton,
      variants: [
        ...(themeOptions.components?.MuiButton?.variants || []),
        /** Contained */
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            border: '1px solid #AB9332',
            '&:hover': {
              border: '1px solid transparent',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            background: '#A4A4A6',
            border: 'transparent',
            '&:hover': {
              color: '#FFF',
              background: '#696969',
            },
          },
        },
        /** Outlined */
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            border: '1px solid #A4A4A6 !important',
            backgroundColor: 'transparent',
            color: 'white',
            '&:hover': {
              color: '#FFF',
              background: hexToRgba('#A4A4A6', 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            backgroundColor: 'transparent',
          },
        },
        /** Text */
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            border: '1px solid transparent',
            color: 'white',
            '&:hover': {
              border: '1px solid #1E293B',
              background: '#A4A4A6',
            },
          },
        },
      ],
    },
  },
};
