import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { baseApi } from 'lib/rtkQuery/baseApi';
import appUIReducer from 'features/appUI/slice';
import blogCategoryReducer from 'features/blog-category/slice';
import airlineTicketsBookingReducer from 'features/booking/airline-tickets/slice';
import amusementTicketBookingReducer from 'features/booking/amusement-tickets/slice';
import insuranceBookingReducer from 'features/booking/insurance/slice';
import hotelBookingReducer from 'features/booking/hotel/slice';
import bookingManagementReducer from 'features/booking-management/common/slice';
import authReducer from 'features/auth/slide';

export const store = configureStore({
  reducer: {
    baseApi: baseApi.reducer,
    ui: appUIReducer,

    blogCategory: blogCategoryReducer,
    airlineTicketsBooking: airlineTicketsBookingReducer,
    amusementTicketsBooking: amusementTicketBookingReducer,
    insuranceBooking: insuranceBookingReducer,
    hotelBooking: hotelBookingReducer,
    bookingManagement: bookingManagementReducer,

    auth: authReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['scanner.onScan', 'modals'],
        ignoredActionPaths: ['payload.onScan', 'payload.element'],
      },
    }).concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
