import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import { TOKEN } from 'features/auth/constants';
import { TCustomerTokens, TTokenExpires } from 'features/auth/types';
import Cookies from 'js-cookie';
import { baseApiEndpoint } from 'lib/constants';
import { QueryResponse } from 'lib/rtkQuery/types';
import qs from 'qs';
import { history } from 'routes/history';
import { getPath } from 'routes/router-paths';

export const setAuthToken = (
  tokenType: TOKEN,
  token: string,
  expiration?: number
) => {
  Cookies.set(tokenType, token, {
    expires: expiration ? (expiration - 60) / 3600 / 24 : 0,
  });
};

/** Get auth token with provided token type
 * App will decode token to get exp and compare with the current time
 * If expired, remove this token and return null
 * If token invalid, remove token and return null
 * If valid, return the token
 *  */
export const getAuthToken = (tokenType: TOKEN): string | null => {
  const token = Cookies.get(tokenType);
  if (!token) return null;
  return token;
};

export const clearAuthToken = () => {
  Cookies.remove(TOKEN.ACCESS_TOKEN);
  Cookies.remove(TOKEN.REFRESH_TOKEN);
};

let isAlreadyFetchingAccessToken = false;
// eslint-disable-next-line @typescript-eslint/ban-types
let subscribers: Function[] = [];

const onAccessTokenFetched = (token: string) => {
  subscribers = subscribers.filter(callback => callback(token));
};

// eslint-disable-next-line @typescript-eslint/ban-types
const addSubscriber = (callback: Function) => {
  subscribers.push(callback);
};

export const handleRefreshToken = (originalRequest: AxiosRequestConfig) => {
  const retryOriginalRequest: Promise<AxiosResponse> = new Promise(resolve => {
    addSubscriber((token: string) => {
      (originalRequest.headers as RawAxiosRequestHeaders).Authorization =
        'Bearer ' + token;
      return resolve(axios(originalRequest));
    });
  });
  const refresh_token = getAuthToken(TOKEN.REFRESH_TOKEN);
  if (!refresh_token) {
    clearAuthToken();
    history.navigate(
      getPath(
        'login',
        qs.stringify({
          redirectedFrom: history.location.pathname,
        })
      )
    );
    return;
  }

  if (!isAlreadyFetchingAccessToken && refresh_token) {
    isAlreadyFetchingAccessToken = true;
    void new Promise((_, reject) => {
      return axios
        .request<unknown, QueryResponse<TCustomerTokens & TTokenExpires>>({
          url: '/UserAuth/Refresh',
          method: 'POST',
          baseURL: baseApiEndpoint,
          headers: {},
          data: { refreshToken: refresh_token },
        })
        .then(response => {
          isAlreadyFetchingAccessToken = false;
          const {
            accessToken,
            refreshToken,
            expireInSeconds,
            refreshTokenExpireIn,
          } = response.data;
          setAuthToken(TOKEN.ACCESS_TOKEN, accessToken, expireInSeconds);
          setAuthToken(TOKEN.REFRESH_TOKEN, refreshToken, refreshTokenExpireIn);
          onAccessTokenFetched(accessToken);
        })
        .catch(error => {
          isAlreadyFetchingAccessToken = false;
          clearAuthToken();
          history.navigate(
            getPath(
              'login',
              qs.stringify({
                redirectedFrom: history.location.pathname,
              })
            )
          );
          return reject(error);
        });
    });
  }

  return retryOriginalRequest;
};
