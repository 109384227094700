import { createElement, ReactNode } from 'react';
import { lazyImport } from 'routes/helpers';
import { getPath } from 'routes/router-paths';
import {
  BOOKING_STEP,
  BOOKING_TYPE,
  GROUP_REPORT_PAGE_TYPE,
  MEMBER_REPORT_PAGE_TYPE,
  PROMOTION_CODE_DETAIL_PAGE_TYPE,
  TRANSACTION_REPORT_PAGE_TYPE,
  USER_REPORT_PAGE_TYPE,
} from 'routes/types';

export type TBreadcrumb = {
  title: string;
  path?: string;
};

export type TRoute = {
  path: string;
  element: ReactNode;
  permissionKeys?: string[];
  pageHeader?: string;
  breadcrumbs?: TBreadcrumb[];
};

export type TPageInfo = {
  pageTitle: string;
};

export const publicRoutes: TRoute[] = [
  {
    path: getPath('login'),
    element: createElement(
      lazyImport(() => import('modules/Authentication/LoginPage')),
      { pageTitle: 'Đăng nhập' }
    ),
  },
];

export const privateRoutes: TRoute[] = [
  {
    path: getPath('dashboard'),
    element: createElement(
      lazyImport(() => import('modules/Miscellaneous/DashboardPage')),
      { pageTitle: 'Dashboard' }
    ),
  },

  /** Website content manager */
  {
    path: getPath('blogCategoriesPage'),
    element: createElement(
      lazyImport(() => import('modules/ContentManagement/BlogCategoriesPage')),
      { pageTitle: 'Danh mục bài viết' }
    ),
  },
  {
    path: getPath('blogPostPage'),
    element: createElement(
      lazyImport(() => import('modules/ContentManagement/BlogPostsPage')),
      { pageTitle: 'Danh sách bài viết' }
    ),
  },
  {
    path: getPath('addEditBlogPostPage', ':postId'),
    element: createElement(
      lazyImport(() => import('modules/ContentManagement/AddEditBlogPostPage'))
    ),
  },
  {
    path: getPath('websiteBannerManagementPage'),
    element: createElement(
      lazyImport(
        () => import('modules/ContentManagement/WebsiteBannerManagementPage')
      ),
      { pageTitle: 'Quản trị banner/quảng cáo' }
    ),
  },

  /** Booking */
  {
    path: getPath(
      'bookingCreatePage',
      ':bookingType' as BOOKING_TYPE,
      ':bookingStep' as BOOKING_STEP
    ),
    element: createElement(
      lazyImport(() => import('modules/Booking/BookingCreatePage'))
    ),
  },
  {
    path: getPath('bookingListPage', ':bookingType' as BOOKING_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Booking/BookingListPage')),
      { pageTitle: 'Danh sách đơn hàng' }
    ),
  },
  {
    path: getPath('bookingInsertPage', ':bookingType' as BOOKING_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Booking/BookingInsertPage')),
      { pageTitle: 'Thêm đơn hàng' }
    ),
  },

  /** Sales */
  {
    path: getPath('groupsManagementPage'),
    element: createElement(
      lazyImport(() => import('modules/Sales/SalesGroupsManagementPage')),
      { pageTitle: 'Quản lý nhóm' }
    ),
  },
  {
    path: getPath('groupDetailPage', ':groupId'),
    element: createElement(
      lazyImport(() => import('modules/Sales/SalesGroupDetailPage'))
    ),
  },
  {
    path: getPath('membersManagementPage'),
    element: createElement(
      lazyImport(() => import('modules/Sales/SalesMembersManagementPage')),
      { pageTitle: 'Quản lý thành viên' }
    ),
  },
  {
    path: getPath('promotionCodesManagementPage'),
    element: createElement(
      lazyImport(() => import('modules/Sales/PromotionCodesManagementPage'))
    ),
  },
  {
    path: getPath(
      'promotionCodeDetailPage',
      ':pageType' as PROMOTION_CODE_DETAIL_PAGE_TYPE
    ),
    element: createElement(
      lazyImport(() => import('modules/Sales/PromotionCodeDetailPage'))
    ),
  },
  {
    path: getPath('sendEmailPage'),
    element: null,
  },

  /** Accounting */
  {
    path: getPath('bookingReportPage', ':bookingType' as BOOKING_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Accounting/BookingReportPage')),
      { pageTitle: 'Danh sách đơn hàng' }
    ),
  },
  {
    path: getPath('memberReportPage', ':reportType' as MEMBER_REPORT_PAGE_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Accounting/MemberReportPage'))
    ),
  },
  {
    path: getPath('userReportPage', ':reportType' as USER_REPORT_PAGE_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Accounting/UserReportPage'))
    ),
  },
  {
    path: getPath('groupReportPage', ':reportType' as GROUP_REPORT_PAGE_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Accounting/GroupReportPage'))
    ),
  },
  {
    path: getPath(
      'paymentGatewayReportPage',
      ':reportType' as TRANSACTION_REPORT_PAGE_TYPE
    ),
    element: createElement(
      lazyImport(() => import('modules/Accounting/TransactionReportPage'))
    ),
  },
  {
    path: getPath('issuingHistoryReportPage', ':bookingType' as BOOKING_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Accounting/PaymentReportPage')),
      { pageTitle: 'Lịch sử thanh toán' }
    ),
  },
  {
    path: getPath('invoiceManagementPage'),
    element: createElement(
      lazyImport(() => import('modules/Accounting/InvoiceManagementPage')),
      { pageTitle: 'Quản lý hóa đơn' }
    ),
  },

  /** Management */
  {
    path: getPath('userAccountsManagementPage'),
    element: createElement(
      lazyImport(
        () => import('modules/Administration/UserAccountsManagementPage')
      ),
      { pageTitle: 'Tài khoản nhân viên' }
    ),
  },
  {
    path: getPath('serviceFeeManagementPage', ':reportType' as BOOKING_TYPE),
    element: createElement(
      lazyImport(() => import('modules/Administration/ServiceFeeReportPage'))
    ),
  },
  {
    path: getPath(
      'pointConversionManagementPage',
      ':reportType' as BOOKING_TYPE
    ),
    element: createElement(
      lazyImport(() => import('modules/Administration/PointConversionPage'))
    ),
  },
];
