import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HotelBookingState,
  THotel,
  THotelDetail,
  THotelSearchFilterData,
  TSearchHotelResponse,
  TSelectedHotelRate,
} from 'features/booking/hotel/types';

const initialState: HotelBookingState = {
  isInitSearchHotel: false,
  isSearchingHotel: false,
  searchHotelResponse: null,
  hotelDetail: null,
  selectedHotelRateDetail: null,
  hotelSearchFilterData: null,
  selectedHotel: null,
};

const hotelBookingSlice = createSlice({
  name: 'hotelBooking',
  initialState,
  reducers: {
    setInitSearchLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isInitSearchHotel = action.payload;
    },

    setSearchHotelLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isSearchingHotel = action.payload;
    },

    setSearchHotelResponse: (
      state,
      action: PayloadAction<TSearchHotelResponse | null>
    ) => {
      state.searchHotelResponse = action.payload;
    },

    setHotelDetail: (state, action: PayloadAction<THotelDetail | null>) => {
      state.hotelDetail = action.payload;
    },

    setSelectedHotelRateDetail: (
      state,
      action: PayloadAction<TSelectedHotelRate | null>
    ) => {
      state.selectedHotelRateDetail = action.payload;
    },

    setHotelSearchFilterData: (
      state,
      action: PayloadAction<THotelSearchFilterData | null>
    ) => {
      state.hotelSearchFilterData = action.payload;
    },

    setSelectedHotel: (state, action: PayloadAction<THotel | null>) => {
      state.selectedHotel = action.payload;
    },
  },
});

export const {
  setInitSearchLoadingState,
  setSearchHotelLoadingState,
  setSearchHotelResponse,
  setHotelDetail,
  setSelectedHotelRateDetail,
  setHotelSearchFilterData,
  setSelectedHotel,
} = hotelBookingSlice.actions;
export default hotelBookingSlice.reducer;
