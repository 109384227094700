import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

type Props = {
  fullScreen: boolean;
};

const SplashScreen = ({ fullScreen }: Props) => {
  return (
    <div
      className={clsx(
        'flex justify-center dark:bg-accent',
        fullScreen
          ? 'fixed left-0 top-0 h-screen w-screen bg-transparent'
          : 'h-full w-full bg-transparent'
      )}
    >
      <div className="flex items-center justify-center">
        <CircularProgress className="h-20 w-20" />
      </div>
    </div>
  );
};

export default SplashScreen;
