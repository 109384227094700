import ErrorIcon from '@mui/icons-material/Error';
import { Button } from '@mui/material';
import { Form, Input, Modal } from 'antd';
import {
  FORM_FIELD,
  FormLabel,
  FormValidation,
} from 'features/user/components/UpdatePasswordForm/constants';
import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

type Props = {
  userName: string;
  onUpdatePasswordFn: (newPassword: string) => void;
  isLoading: boolean;
  open: boolean;
};

type TFormData = {
  userName: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const { confirm } = Modal;

const UpdatePasswordForm = forwardRef(
  (
    { userName, onUpdatePasswordFn, isLoading, open }: Props,
    ref: ForwardedRef<HTMLButtonElement | null>
  ) => {
    const [form] = Form.useForm();
    const { setFieldsValue, resetFields } = form;

    const initialValues = useMemo(() => {
      return {
        [FORM_FIELD.USERNAME]: userName ?? '',
      };
    }, [userName]);

    useEffect(() => {
      setFieldsValue(initialValues);
    }, [initialValues, setFieldsValue]);

    const handleConfirm = useCallback(
      (newPassword: string) => {
        onUpdatePasswordFn(newPassword);
        resetFields();
      },
      [onUpdatePasswordFn, resetFields]
    );

    const onFinish = useCallback(
      async (values: TFormData) => {
        try {
          const { newPassword } = values;

          confirm({
            title: (
              <div className="ant-modal-confirm-heading">
                <ErrorIcon className="icon-danger" />
                <span>Cảnh báo</span>
              </div>
            ),
            icon: null,
            content: (
              <p className="mb-4">
                Bạn có chắc chắn muốn cập nhật mật khẩu của tài khoản{' '}
                <strong>{userName}</strong>?
              </p>
            ),
            onOk: () => handleConfirm(newPassword),
            okButtonProps: {
              disabled: isLoading,
              className: 'bg-primary hover:!bg-primary hover:opacity-75',
            },
            cancelButtonProps: {
              className:
                '!border !border-solid !border-primary !text-primary hover:!bg-[--color-primary-light]',
            },
            centered: true,
          });
        } catch (error) {}
      },
      [userName, isLoading, handleConfirm]
    );

    useEffect(() => {
      if (!open) resetFields();
    }, [open, resetFields]);

    return (
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name={FORM_FIELD.USERNAME}
          label={FormLabel[FORM_FIELD.USERNAME]}
          rules={FormValidation[FORM_FIELD.USERNAME]}
        >
          <Input placeholder={FormLabel[FORM_FIELD.USERNAME]} disabled />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD.NEW_PASSWORD}
          label={FormLabel[FORM_FIELD.NEW_PASSWORD]}
          rules={FormValidation[FORM_FIELD.NEW_PASSWORD]}
        >
          <Input.Password placeholder={FormLabel[FORM_FIELD.NEW_PASSWORD]} />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD.NEW_PASSWORD_CONFIRM}
          label={FormLabel[FORM_FIELD.NEW_PASSWORD_CONFIRM]}
          rules={FormValidation[FORM_FIELD.NEW_PASSWORD_CONFIRM]}
        >
          <Input.Password
            placeholder={FormLabel[FORM_FIELD.NEW_PASSWORD_CONFIRM]}
          />
        </Form.Item>

        <Button type="submit" ref={ref} className="hidden">
          Xác nhận
        </Button>
      </Form>
    );
  }
);

export default memo(UpdatePasswordForm);
