export enum TOKEN {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  SALES = 'SALE',
  TICKETING = 'TICKETING',
  ACCOUNTING = 'ACCOUNTING',
  CONTENT_EDITOR = 'CONTENT',
}
