import { RootState } from 'lib/stores';

export const listViewStateSelector = (state: RootState) => state.ui.isListView;

export const sidebarCollapsedStateSelector = (state: RootState) =>
  state.ui.isSidebarCollapsed;

export const selectedMenuKeysSelector = (state: RootState) =>
  state.ui.selectedMenuKeys;

export const fullContentViewStateSelector = (state: RootState) =>
  state.ui.fullContentView;

export const appPrimaryColorSelector = (state: RootState) =>
  state.ui.appPrimaryColor;

export const darkModeSelector = (state: RootState) => state.ui.darkMode;
