import { Button } from '@mui/material';
import { Result } from 'antd';
import AppHelmet from 'components/AppHelmet';
import { useNavigate } from 'react-router-dom';
import { getPath } from 'routes/router-paths';
import notFoundBanner from 'assets/svgs/404.svg';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <main className="flex h-screen w-screen items-center justify-center p-15 dark:bg-accent">
      <AppHelmet title="Không tìm thấy trang" />
      <Result
        icon={<img src={notFoundBanner} alt="404" width={350} height={350} />}
        title={<strong className="text-2xl">Không tìm thấy trang</strong>}
        subTitle="Trang bạn tìm kiếm không tồn tại. Vui lòng quay lại trang trước hoặc trở lại trang chủ."
        extra={
          <div className="flex justify-center gap-x-4">
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Quay lại trang trước
            </Button>
            <Button onClick={() => navigate(getPath('dashboard'))}>
              Về trang chủ
            </Button>
          </div>
        }
      />
    </main>
  );
};

export default NotFoundPage;
