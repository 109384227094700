import message from 'antd/es/message';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { TOKEN } from 'features/auth/constants';
import { getAuthToken, handleRefreshToken } from 'features/auth/helpers';
import { HttpClientProps, TQueryConfig, TQueryError } from 'lib/axios/types';
import { history } from 'routes/history';
import { getPath } from 'routes/router-paths';
import { startLoadingSpinner, stopLoadingSpinner } from 'utils/loadingSpinner';
import { ObjectType } from 'utils/types';

const baseURL = import.meta.env.VITE_APP_BASE_URL;

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig & TQueryConfig) => {
    const { disableSpinner } = config;
    if (!disableSpinner) {
      startLoadingSpinner();
    }
    return config;
  },
  (error: AxiosError) => {
    stopLoadingSpinner();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    stopLoadingSpinner();
    return response.data;
  },
  async (error: AxiosError) => {
    const { response, code, config } = error;
    const { manuallyHandlingErrorMsg } = config as AxiosError['config'] &
      TQueryConfig;
    stopLoadingSpinner();
    if (response?.status === 403) {
      message.error('Bạn không có quyền truy cập hoặc sử dụng tính năng này.');
      history.navigate(getPath('dashboard'));
    }
    if (response?.status === 408 || code === 'ECONNABORTED') {
      message.error('Quá thời gian xử lý');
    }
    if (
      response?.status === 401 &&
      ![`${baseURL}/UserAuth/Authenticate`, '/UserAuth/Refresh'].includes(
        config?.url as string
      )
    ) {
      try {
        return await handleRefreshToken(config as AxiosRequestConfig);
      } finally {
        stopLoadingSpinner();
      }
    }

    !manuallyHandlingErrorMsg &&
      message.error(
        // @ts-ignore
        response?.data?.message ??
          'Lỗi không xác định. Vui lòng liên hệ quản trị.'
      );

    return Promise.reject(manuallyHandlingErrorMsg ? response?.data : error);
  }
);

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async (config: HttpClientProps & TQueryConfig) => {
    const {
      url,
      method,
      data,
      params,
      customHeader,
      manuallyHandlingErrorMsg,
      disableSpinner,
    } = config;

    const accessToken = getAuthToken(TOKEN.ACCESS_TOKEN);

    const axiosConfig = {
      url: baseUrl + url,
      method,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...customHeader,
      },
      ...(params && { params }),
      ...(data && { data }),
      manuallyHandlingErrorMsg,
      disableSpinner,
    } as AxiosRequestConfig<ObjectType> & TQueryConfig;

    try {
      const result = await axios(axiosConfig);
      return {
        data: result,
      };
    } catch (err: any) {
      return {
        data: null,
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message || err.error,
          success: err.success || false,
        } as TQueryError,
      };
    }
  };
